export const LOADING_START = 'LOADING_START';
export const LOADING_FINISH = 'LOADING_FINISH';

export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const FETCH_ARTICLES_INIT = 'FETCH_ARTICLES_INIT';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FOR_USER_SUCCESS = 'FETCH_ARTICLES_FOR_USER_SUCCESS';
export const FETCH_ARTICLES_FAIL = 'FETCH_ARTICLES_FAIL';
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL';
export const CREATE_ARTICLE_INIT = 'CREATE_ARTICLE_INIT';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL';
export const DELETE_ARTICLE_INIT = 'DELETE_ARTICLE_INIT';

export const FETCH_CABINS = 'FETCH_CABINS';
export const FETCH_CABINS_SUCCESS = 'FETCH_CABINS_SUCCESS';
export const FETCH_CABINS_FOR_USER_SUCCESS = 'FETCH_CABINS_FOR_USER_SUCCESS';
export const FETCH_CABINS_FAIL = 'FETCH_CABINS_FAIL';
export const CREATE_CABIN = 'CREATE_CABIN';
export const CREATE_CABIN_INIT = 'CREATE_CABIN_INIT';
export const CREATE_CABIN_SUCCESS = 'CREATE_CABIN_SUCCESS';
export const CREATE_CABIN_FAIL = 'CREATE_CABIN_FAIL'
export const DELETE_CABIN = 'DELETE_CABIN';
export const DELETE_CABIN_SUCCESS = 'DELETE_CABIN_SUCCESS';
export const DELETE_CABIN_INIT = 'DELETE_CABIN_INIT';
export const DELETE_CABIN_FAIL = 'DELETE_CABIN_FAIL';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';
export const FETCH_EVENTS_FOR_USER_SUCCESS = 'FETCH_EVENTS_FOR_USER_SUCCESS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL'
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';

export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const SET_SUCCESS_MESSAGES = 'SET_SUCCESS_MESSAGES';
export const CLEAR_SUCCESS_MESSAGES = 'CLEAR_SUCCESS_MESSAGES';